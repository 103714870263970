export default {
  dataError() {
    const errors = []
    if (!this.$v.dataSweepstake.$dirty) return errors
    !this.$v.dataSweepstake.required && errors.push('Selecione uma data!')
    return errors
  },

  loteriaError() {
    const errors = []
    if (!this.$v.loteria.$dirty) return errors
    !this.$v.loteria.required && errors.push('Selecione uma loteria!')
    return errors
  },

  modalidadeError() {
    const errors = []
    if (!this.$v.modalidade.$dirty) return errors
    !this.$v.modalidade.required && errors.push('Selecione uma modalidade!')
    return errors
  }
}