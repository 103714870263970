<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Cotação Dinâmica</h1>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text class="modal-cadastro-form pa-3 pb-0">
              <v-row>               
                <v-col class="pa-3 pt-0 pb-1" cols="12" md="2" sm="12">
                  <ModalDatePicker 
                    @changeDate="changeDate" 
                    :min="dateNow"
                    :error-messages="dataError"
                  />
                </v-col>

                <v-col class="pa-3 pt-0 pb-1" cols="12" md="2" sm="12">
                  <v-autocomplete
                    v-model="loteria"
                    :items="listSelectSweepstakes"
                    dense
                    label="Loterias"
                    :loading="loadingSweepstake"
                    :error-messages="loteriaError"
                    clearable
                  />
                </v-col>
                <v-col class="pa-3 pt-0 pb-1" cols="12" md="2" sm="12">
                  <v-autocomplete
                    v-model="modalidade"
                    :items="listModalities"
                    dense
                    label="Modalidade"
                    :error-messages="modalidadeError"
                    :loading="loadingModality"
                    clearable
                  />
                </v-col>
                
                <v-col class="pa-3 pt-0 pb-1" cols="12" md="2" sm="12">
                  <v-btn
                    type="submit"
                    @click="filtrarCotacao"
                    :color="variables.colorPrimary"
                    :loading="loadingBtnFilter"
                    dark
                  >Filtrar</v-btn>
                </v-col>
              </v-row>
              <v-row class="justify-space-between">               
                <v-col class="pa-3 pt-0 pb-1" cols="12" md="2" sm="12">
                  <v-btn
                    type="submit"
                    @click="activeModalAddCotacao"
                    :color="variables.colorPrimary"
                    dark
                    :loading="loadingBtnCotacao"
                    v-if="permiteAcao($route, 'add')"
                  >Adicionar cotação</v-btn>
                </v-col>

                <v-col class="pa-3 pt-0 pb-1 text-right" cols="12" md="2" sm="12">
                  <v-btn
                    type="submit"
                    @click="activeModalConfigLimites()"
                    :color="variables.colorSecondary"
                    dark
                    :loading="loading"
                    v-if="permiteAcao($route, 'add')"
                  >Configurar limites</v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="pt-0">
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div class="box-table box-table-ganhadores">
         <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :items="listCotacaoDinamica"
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"
          >

          <template v-slot:item.usedValue="{ item }">
            {{ item.usedValue | currency }}
          </template>

          <template v-slot:item.remaingValue="{ item }">
            <v-chip v-if="!isExibir(item.remaingValue)" :color="variables.colorError" dark>Bloqueado</v-chip>
            <template v-else>
              {{ item.remaingValue| currency }}
            </template>
          </template>

          <template v-slot:item.action="{item}">
             <v-btn
              v-if="permiteAcao($route, 'edit') && isExibir(item.remaingValue)"
              small
              @click="bloquear(item)"
              tile
              outlined 
              :color="variables.colorError"
            >
              Bloquear
            </v-btn>
          </template>
          <template v-slot:footer>
            <div class="wrapper-footer">
              <div class="qtd-paginacao">
                {{ pageStart }}
                -
                {{ pageStop }}
                de
                {{ totalItens }}
              </div>
              <v-pagination
                v-model="page"
                :length="numberOfPages"
                circle
                :disabled="disabled"
                :total-visible="7"
                class="box-table-paginacao"
                v-if="numberOfPages > 1"></v-pagination>
            </div>
          </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
     <Modal 
        :activeModal="activeModal"
        :widthModal="450"
        @closeModal="closeModal"
      >
        <FormCotacao 
          @closeModal="closeModal"
          @registrarCotacao="registrarCotacao"
          :configModaliade="selectedMolidade"
        />
      </Modal>
      
     <Modal 
        :activeModal="isActiveModalConfigLimites"
        :widthModal="500"
        @closeModal="closeModalConfigLimites"
      >
        <FormConfigLimites 
          @closeModal="closeModalConfigLimites"
          :activeModal="isActiveModalConfigLimites"
        />
      </Modal>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// eslint-disable-next-line no-unused-vars
import { map, reduce, findIndex } from 'lodash'
// eslint-disable-next-line no-unused-vars
import moment from 'moment'

// eslint-disable-next-line no-unused-vars
import Events from '@/core/service/events'
// eslint-disable-next-line no-unused-vars
import { currency } from '@/core/service/utils'
import listagemTables from '@/views/mixins/listagemTables'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import validade from './validator'

export default {
  name: 'CotacaoDinamica',
  components: {
    FormCotacao: () => import('./components/formCotacao'),
    FormConfigLimites: () => import('./components/formConfigLimites'),
    ModalDatePicker: () => import('@/views/components/modalDatePicker'),
    Modal: () => import('@/views/components/modal')
  },
  mixins: [listagemTables, validationMixin],
  data: () => ({
    modalidade: '',
    loteria: '',
    dataSweepstake: moment().format('YYYY-MM-DD'),
    headers: [
      { align: 'start', class: 'table-header', text: 'Loteria', value: 'sweep.cod' },
      { align: 'start', class: 'table-header', text: 'Palpite', value: 'guess' },
      { align: 'start', class: 'table-header', text: 'Modalidade', value: 'modality.name' },
      { align: 'start', class: 'table-header', text: 'Valor usado', value: 'usedValue' },
      { align: 'start', class: 'table-header', text: 'Valor restante', value: 'remaingValue' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],
    page: 1,
    dateNow:  moment().format('YYYY-MM-DD'),
    activeModal: false,
    isActiveModalConfigLimites: false,
    loading: false,
    loadingSweepstake: false,
    loadingBtnCotacao: false,
    loadingModality: false,
    loadingBtnFilter: false
  }),

  watch: {
    'dataSweepstake' (val) {
      const _addlFilter = {
        'Sweepstakes:dtEnd_>=': moment(val).format('YYYY-MM-DD 00:00:00'),
        'Sweepstakes:dtEnd_<=': moment(val).format('YYYY-MM-DD 23:59:59'),
      }

      this.loadingSweepstake = true
      this.getSweepstakes({ _addlFilter })
        .catch(e => errorSnackbar(e.error))
        .finally(() => this.loadingSweepstake = false)
    }
  },

  mounted () {
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('cotacaoDinamica', {
      listCotacaoDinamica: 'data',
      listaItens: 'listaItens',
      totalItens: 'totalItens'
    }),
    ...mapGetters('sweepstakes', {
      listSweepstakes: 'listaItens'
    }),
    ...mapGetters('modalities', {
      listModalities: 'listaItensSelect',
      listaItens: 'listaItens'
    }),

    listSweepstakesFilter () {
      return this.listSweepstakes.filter(sweep => sweep.text !== 'S6' || sweep.text !== 'BINGO')
    },

    ...validade,

    selectedMolidade () {
      return this.listaItens.filter(item => item.id === this.modalidade)
    },

    listSelectSweepstakes () {
      const arryMethodIds = ['PROG']
      return this.listSweepstakes
                  .filter(item => arryMethodIds.includes(item.ascertainedMethodId))
                  .map(item => ({
                    text: item.cod,
                    value: item.id
                 }))
    }
  },

  methods: {
    ...mapActions('cotacaoDinamica', ['getItens', 'bloquearCotacao', 'registerCotacao']),
    ...mapActions('sweepstakes', {
      getSweepstakes: 'getSweepstakes'
    }),
    ...mapActions('modalities', {
      getModalities: 'getListData'
    }),
    init () {
      const _addlFilter = {
        'Sweepstakes:dtEnd_>=': moment().format('YYYY-MM-DD 00:00:00'),
        'Sweepstakes:dtEnd_<=': moment().format('YYYY-MM-DD 23:59:59'),
      }

      this.loadingSweepstake = true
      this.getSweepstakes({ _addlFilter })
        .catch(e => errorSnackbar(e.error))
        .finally(() => this.loadingSweepstake = false)

      this.loadingModality = true
      this.getModalities()
        .catch(e => errorSnackbar(e.error))
        .finally(() => this.loadingModality = false)
      

      this.loadingTable = true
      const _filter2 = {
        'DynamicQuotations:created_>=': moment().format('YYYY-MM-DD 00:00:00'),
        'DynamicQuotations:created_<=': moment().format('YYYY-MM-DD 23:59:59'),
      }
      this.getItens({ _addlFilter: { ..._filter2 } })
        .catch(e => errorSnackbar(e.error))
        .finally(() => this.loadingTable = false)
    },

    changeDate (date) {
      this.dataSweepstake = date
    },

    filtrarCotacao () {
      let _addlFilter = {}

      if (this.loteria) {
        _addlFilter = {
          'DynamicQuotations:sweepstakeId_=': this.loteria ? this.loteria : undefined,
          'DynamicQuotations:modalityId_=': this.modalidade ? this.modalidade : undefined
        }
      } else {
        _addlFilter = {
          'DynamicQuotations:created_>=': moment(this.dataSweepstake).format('YYYY-MM-DD 00:00:00'),
          'DynamicQuotations:created_<=': moment(this.dataSweepstake).format('YYYY-MM-DD 23:59:59'),
          'DynamicQuotations:modalityId_=': this.modalidade ? this.modalidade : undefined
        }
      }
            
      this.loadingBtnFilter = true
      this.getItens({ _addlFilter })
        .catch(e => errorSnackbar(e.error))
        .finally(() => this.loadingBtnFilter = false)
    },

    bloquear (cotacao) {
      this.$swal({
        icon: 'warning',
        text: `Tem certeza que deseja bloquear o palpite: ${cotacao.guess}?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          this.loadingPag = true
          this.bloquearCotacao({id: cotacao.id}).then(() =>{
            successSnackbar('Cotação bloqueada com sucesso!')
            this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
              .then()
              .catch(err => {
                errorSnackbar(err.error)
              })
              .finally(() => this.loadingPag = false)
          }).catch(err => {
            this.loadingPag = false
            errorSnackbar(err.error)
          })
        }
      })
    },

    closeModal () {
      this.activeModal = false
    },

    activeModalAddCotacao () {
      if (this.$v.$invalid) {
        this.$v.$touch()

        return false
      }

      this.activeModal = true
    },

    activeModalConfigLimites () {
      this.isActiveModalConfigLimites = true
    },

    closeModalConfigLimites () {
      this.isActiveModalConfigLimites = false
    },

    registrarCotacao (palpite) {
      this.loadingBtnCotacao = true
      this.activeModal = false

      this.registerCotacao({
        sweepstakeId: this.loteria,
        modalityId: this.modalidade,
        guess: palpite,
        usedValue: 9999
      })
      .then(() => {
        this.loadingTable = true
        this.getItens({ _addlFilter: {
          'DynamicQuotations:sweepstakeId_=': this.loteria ? this.loteria : undefined,
        } })
          .finally(() => this.loadingTable = false)
        successSnackbar('Cadastro realizado com sucesso!')
      })
      .catch(e => errorSnackbar(e.error))
      .finally(() => this.loadingBtnCotacao = false)
    },

    remaingValue (valor) {
      return valor < 0 ? 0 : valor
    },

    paginacao (pag) {
      this.loadingPag = true
      this.disabled = true
      this.setPage(pag)
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

      let _addlFilter = {}

      if (this.loteria) {
        _addlFilter = {
          'DynamicQuotations:sweepstakeId_=': this.loteria ? this.loteria : undefined,
          'DynamicQuotations:modalityId_=': this.modalidade ? this.modalidade : undefined
        }
      } else {
        _addlFilter = {
          'DynamicQuotations:created_>=': moment(this.dataSweepstake).format('YYYY-MM-DD 00:00:00'),
          'DynamicQuotations:created_<=': moment(this.dataSweepstake).format('YYYY-MM-DD 23:59:59'),
          'DynamicQuotations:modalityId_=': this.modalidade ? this.modalidade : undefined
        }
      }

      this.getItens({ page: pag, pageSize: this.$store.getters.ItensPaginacao, _addlFilter })
      .finally(() => {
        this.loadingPag = false
        this.disabled = false
      })
    },

    isExibir (valor) {
      return valor > 0
    }
  },

  validations () {
    return  {
      dataSweepstake: { required },
      loteria: { required },
      modalidade: { required }
    }
  }

}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .bug-input{
    position: relative;
    top: -4px;
  }
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }
 .v-btn--contained.button-premio {
    font-size: 14px;
    border-radius: 0 !important;
    color: $colorPrimary !important;
    border: 1px solid $colorPrimary !important;
    box-shadow: none !important;
    font-weight: normal !important;
    &-alto {
      border-radius: 0 !important;
      font-size: 14px;
      font-weight: normal !important;
      box-shadow: transparent !important;
      color: white !important;
    }
  }
  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }

    &.box-table-ganhadores {
     .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        // padding: 15px 0;
      } 

      .v-data-table tbody tr.v-data-table__expanded__content {
        box-shadow: none;
      }
    }

    .box-table-palpites {
      .v-data-table {
        margin: 20px 0
      }
    }
    
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
  .position-buttons {
    @media(max-width: 600px) {
      text-align: center;
    }
  }
</style>>
